<template>
  <div>
    <input-text
      name="personalLink"
      label-text="Ваша персональная ссылка"
      :readonly="true"
      :value="personalLink"
    />
    <div class="row align-items-center">
      <div class="col-6 col-lg-4">
        <a
          href="javascript:void(0);"
          class="button"
          v-clipboard:copy="personalLink"
          v-clipboard:success="copySuccess"
        >
          Скопировать
        </a>
      </div>
      <div class="col-12 col-lg-8">
        <social-networks
          class="mt-5 mt-lg-0"
          :link="personalLink"
          :title="socialNetwork.title"
          :description="socialNetwork.description"
        />
      </div>
    </div>
    <p class="mt-4 light small">
      При переходе пользователя по&#160;вашей ссылке мы закрепляем за&#160;вами все сделки, которые он создаст
      в&#160;течение 30 (для существующих пользователей) или 90 (для новых пользователей) дней.
    </p>
    <h4 class="h4 mt-5 mb-3 font-weight-normal">Ссылка для размещения на сайте</h4>
    <input-text
      name="customText"
      label-text="Текст ссылки"
      :value="linkCustomText"
      @setValue="linkCustomText = $event"
    />
    <div class="checkbox mb-5">
      <input class="checkbox__input" id="linkBlank" type="checkbox" name="linkBlank" v-model="linkBlank">
      <label class="checkbox__label" for="linkBlank">
        Открывать ссылку в новой вкладке
      </label>
    </div>
    <text-area
      name="linkFullText"
      label-text="Код для вставки на сайт"
      :value="linkFullText"
      :readonly="true"
    />
    <div class="row">
      <div class="col-6 col-lg-4">
        <a
          href="javascript:void(0);"
          class="button"
          v-clipboard:copy="linkFullText"
          v-clipboard:success="copySuccess"
        >
          Скопировать
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/common/InputText'
import TextArea from '@/components/common/TextArea'
import SocialNetworks from '@/components/common/SocialNetworks'
import { mapState } from 'vuex'

export default {
  name: 'InstrumentsTab',
  components: {
    InputText,
    TextArea,
    SocialNetworks
  },
  data () {
    return {
      linkBlank: true,
      linkCustomText: 'Наложка.рф - Сервис безопасных сделок',
      socialNetwork: {
        title: 'Наложка.рф - Сервис безопасных сделок',
        description: 'С сервисом Наложка покупать и продавать товары в интернете просто и безопасно.'
      }
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    personalLink () {
      return `${window.envVariables.PARTNERS_URL}?ref_id=${this.user['~id']}`
    },
    linkFullText () {
      return '<a href="' + this.personalLink + '"' + (this.linkBlank ? ' target="_blank"' : '') + '>' + this.linkCustomText + '</a>'
    }
  },
  methods: {
    copySuccess () {
      this.$notify({ type: 'warn', text: 'Скопировано!' })
    }
  }
}
</script>
