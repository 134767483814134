<template>
  <page-wrapper>
    <template v-slot:before_content>
      <user-info class="pt-5" />
    </template>
    <template v-slot:content>
      <h2 class="h2 h2_bold">Инструменты партнёра</h2>
    </template>
    <template v-slot:content_no_wrap>
      <div class="tabs tabs_inner">
        <nav class="tabs__nav d-lg-flex">
          <a
            v-for="item in tabsList"
            :key="'nav_' + item.id"
            href="javascript:void(0);"
            class="tabs__link"
            :class="{ 'tabs__link_active': activeTab === item.id }"
            @click.prevent="activeTab = item.id"
          >
            {{ item.name }}
          </a>
        </nav>
      </div>
      <div class="content-block__body">
        <keep-alive>
          <component :is="activeTab"></component>
        </keep-alive>
      </div>
    </template>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@/components/common/PageWrapper'
import UserInfo from '@/components/common/UserInfo'
import InstrumentsTab from '@/components/instruments/InstrumentsTab'
import BannersTab from '@/components/instruments/BannersTab'
import { mapState } from 'vuex'

export default {
  name: 'Instruments',
  components: {
    PageWrapper,
    UserInfo,
    InstrumentsTab,
    BannersTab
  },
  data () {
    return {
      tabsList: [
        {
          id: 'InstrumentsTab',
          name: 'Ссылки'
        },
        {
          id: 'BannersTab',
          name: 'Баннеры'
        }
      ],
      activeTab: null
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  mounted () {
    const isRouteProp = this.$route.params.prop && this.tabsList.find(x => x.id === this.$route.params.prop)
    this.activeTab = isRouteProp ? this.$route.params.prop : this.tabsList[0].id
  }
}
</script>
