<template>
  <div>
    <h4 class="h4 mb-4 font-weight-normal">Наложка 310 * 445 — Баннер</h4>
    <div class="row">
      <div class="col-8 col-lg-5">
        <div class="banner_wrapper" v-html="verticalBannerImg"></div>
      </div>
      <div class="col-12 col-lg-7">
        <div class="checkbox mb-3">
          <input class="checkbox__input" id="verticalBannerBlank" type="checkbox" name="verticalBannerBlank" v-model="verticalBannerBlank">
          <label class="checkbox__label" for="verticalBannerBlank">
            Открывать ссылку в новой вкладке
          </label>
        </div>
        <text-area
          name="verticalBannerText"
          label-text="Код для вставки на сайт"
          :value="verticalBannerText"
          :readonly="true"
        />
        <div class="row">
          <div class="col-12 col-lg-8">
            <a
              href="javascript:void(0);"
              class="button"
              v-clipboard:copy="verticalBannerText"
              v-clipboard:success="copySuccess"
            >
              Скопировать
            </a>
          </div>
        </div>
      </div>
    </div>
    <h4 class="h4 mt-5 mb-4 font-weight-normal">Наложка 805 * 140 — Баннер</h4>
    <div class="banner_wrapper mb-4" v-html="horizontalBannerImg"></div>
    <div class="row">
      <div class="col-12 col-lg-7">
        <div class="checkbox mb-3">
          <input class="checkbox__input" id="horizontalBannerBlank" type="checkbox" name="horizontalBannerBlank" v-model="horizontalBannerBlank">
          <label class="checkbox__label" for="horizontalBannerBlank">
            Открывать ссылку в новой вкладке
          </label>
        </div>
        <text-area
          name="horizontalBannerText"
          label-text="Код для вставки на сайт"
          :value="horizontalBannerText"
          :readonly="true"
        />
        <div class="row">
          <div class="col-12 col-lg-8">
            <a
              href="javascript:void(0);"
              class="button"
              v-clipboard:copy="horizontalBannerText"
              v-clipboard:success="copySuccess"
            >
              Скопировать
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextArea from '@/components/common/TextArea'
import { mapState } from 'vuex'

export default {
  name: 'BannersTab',
  components: {
    TextArea
  },
  data () {
    return {
      verticalBannerBlank: true,
      horizontalBannerBlank: true
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    personalLink () {
      return `${window.envVariables.PARTNERS_URL}?ref_id=${this.user['~id']}`
    },
    verticalBannerImg () {
      return '<img src="' + window.envVariables.CDN_URL + '/img/banner_310_445.png" alt="Наложка.рф - Сервис безопасных сделок" width="310" />'
    },
    verticalBannerText () {
      return '<a href="' + this.personalLink + '"' + (this.verticalBannerBlank ? ' target="_blank"' : '') + '>' + this.verticalBannerImg + '</a>'
    },
    horizontalBannerImg () {
      return '<img src="' + window.envVariables.CDN_URL + '/img/banner_805_140.png" alt="Наложка.рф - Сервис безопасных сделок" width="805" />'
    },
    horizontalBannerText () {
      return '<a href="' + this.personalLink + '"' + (this.horizontalBannerBlank ? ' target="_blank"' : '') + '>' + this.horizontalBannerImg + '</a>'
    }
  },
  methods: {
    copySuccess () {
      this.$notify({ type: 'warn', text: 'Скопировано!' })
    }
  }
}
</script>

<style lang="scss">
.banner_wrapper {
  width: 100%;
  border: 1px solid #e2e5e9;
  img {
    display: block;
    width: 100%;
  }
}
</style>
