<template>
  <div class="px-5 px-lg-0" v-if="user">
    <h2 class="mb-3">{{ `${user.firstname} ${user.lastname}` }}</h2>
    <div class="row justify-content-between no-gutters flex-nowrap flex-lg-nowrap">
      <div>
        <p class="mb-0">Email: {{ user.email }}</p>
        <p class="mb-0">Баланс: {{ balanceInfo ? `${balanceInfo.balance}&#160;₽` : '...' }}</p>
        <p class="mb-0">ID: {{ user['~id'] }}</p>
      </div>
      <div class="text-right font-weight-bold pl-3">
        <p class="mb-0">Общий доход в&#160;этом месяце:</p>
        <h3 class="font-weight-bold">{{ balanceInfo ? `${balanceInfo.month_profit}&#160;₽` : '...' }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UserInfo',
  data () {
    return {
      balanceInfo: null
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  beforeMount () {
    this.$axios.get(this.getPathRequest('balance_info'))
      .then(response => {
        this.balanceInfo = response.data
      })
      .catch(error => {
        this.openModal('ErrorModal', {
          title: 'Ошибка получения данных пользователя',
          text: error.response.data.message
            ? error.response.data.message
            : 'Во время получения данных пользователя произошла ошибка. Пожалуйста, попробуйте снова или обратитесь к нам в тех. поддержку.'
        })
      })
  }
}
</script>
