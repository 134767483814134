<template>
  <div class="social-list d-flex justify-content-between justify-content-lg-start">
    <share-network
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        :style="{ backgroundColor: network.color }"
        :url="sharing.url"
        :title="sharing.title"
        :description="sharing.description"
        :media="sharing.media"
        class="social-list-item svg"
    >
      <svg
          class="icon"
          :class="'icon-' + network.icon"
      >
        <use v-bind:xlink:href="'#icon-' + network.icon"></use>
      </svg>
    </share-network>
  </div>
</template>

<script>
import shareNetwork from '@/components/common/ShareNetwork'

export default {
  name: 'SocialNetworks',
  components: {
    shareNetwork
  },
  data () {
    return {
      sharing: {
        url: this.link,
        title: this.title ? this.title : '',
        description: this.description ? this.description : '',
        media: this.media ? this.media : ''
      },
      networks: [
        { network: 'vk', icon: 'social_vk', color: '#4a76a8' },
        { network: 'odnoklassniki', icon: 'social_odnoklassniki', color: '#ed812b' },
        { network: 'facebook', icon: 'social_facebook', color: '#1877f2' },
        { network: 'whatsapp', icon: 'social_whatsapp', color: '#25d366' },
        { network: 'telegram', icon: 'social_telegram', color: '#0088cc' },
        { network: 'viber', icon: 'social_viber', color: '#59267c' },
        { network: 'twitter', icon: 'social_twitter', color: '#1DA1F2' }
      ]
    }
  },
  props: {
    link: {
      type: String,
      required: true
    },
    title: {
      type: [String, null]
    },
    description: {
      type: [String, null]
    },
    media: {
      type: [String, null]
    }
  }
}
</script>

<style lang="scss" scoped>
  .social-list {

    &-item {
      width: 2rem;
      padding-bottom: 2rem;
      border-radius: 50%;
      margin-right: 0.5rem;
      transition: box-shadow 400ms;

      svg {
        fill: #ffffff;
        top: 22%;
        left: 22%;
        width: 56%;
        height: 56%;
      }
      &:hover {
        box-shadow: 0 3px 4px 2px rgba(132, 141, 154, 0.45);
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 991.98px) {
    .social-list {

      &-item {
        width: 4.7rem;
        padding-bottom: 4.7rem;
      }
    }
  }
</style>
