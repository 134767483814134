<template>
  <div class="content-wrapper">
    <div v-if="hasSlot('before_content')" class="container-outer container-outer_width-720">
      <slot name="before_content"></slot>
    </div>
    <div class="container-outer container-outer_width-720 pt-5 pb-5">
      <div class="content-block">
        <div v-if="hasSlot('content')" class="content-block__body">
          <div class="container-fluid">
            <slot name="content"></slot>
          </div>
        </div>
        <slot name="content_no_wrap"></slot>
      </div>
    </div>
    <div v-if="hasSlot('after_content')" class="container-outer container-outer_width-720">
      <slot name="after_content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageWrapper',
  methods: {
    hasSlot (name = 'default') {
      return !!this.$slots[name]
    }
  }
}
</script>
